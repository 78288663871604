<template>
    <lassoLayout class="page-email-congratulations">
        <div class="p_email_congratulations">
            <v-container>
                <div class="congratulations">
                    <div class="_heading">
                        <h1>Congratulations!</h1>
                        <h6>Your email has been verified</h6>
                    </div>
                    <div class="illustration congratul"></div>
                    <div class="action_buttons">
                        <router-link :to="{name: 'login'}" class="small c2a_btn" v-ripple>
                            Log in
                            <span class="arrow">
                                <span class="line_svg"></span>
                                <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
                                    <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
                                </svg>
                            </span>
                        </router-link>
                        <router-link :to="{name: 'explore'}" class="small tetriatary_btn" v-ripple>Home</router-link>
                    </div>
                </div>
            </v-container>
        </div>
    </lassoLayout>
</template>

<script>
import lassoLayout from "@/layouts/lassoLayout.vue";
export default {
    name: "email-congratulations",
    components: {
        lassoLayout
    },
}
</script>

<style lang="scss">

</style>
